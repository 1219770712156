#iubenda_policy {
  font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  color: #505762;
}

#iubenda_policy {
  font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif !important;
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  color: #505762 !important;
}

#iubenda_policy.iubenda_fluid_policy .iub_container {
  margin-top: 30px;
  margin-bottom: 30px;
}

#iubenda_policy .iub_base_container {
  border-radius: 3px;
  background: #fff;
  color: #595858;
  position: relative;
}

#iubenda_policy .iub_base_container {
  color: #595858 !important;
}

#iubenda_policy #wbars {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#iubenda_policy .iub_content {
  position: relative;
  padding: 25px 30px;
  margin: 0 auto;
  border-radius: 3px 3px 0 0;
}

#iubenda_policy h2 {
  font-size: 17px !important;
  font-weight: bold !important;
  line-height: 21px !important;
  padding-top: 21px !important;
}

#iubenda_policy h3 {
  font-size: 13px !important;
  line-height: 19px !important;
  font-weight: bold !important;
  padding-top: 24px !important;
}

#iubenda_policy h4 {
  font-size: 13px !important;
  font-weight: bold !important;
  padding-top: 19px !important;
  margin-bottom: 0 !important;
}

#iubenda_policy h1, #iubenda_policy h2, #iubenda_policy h3 {
  color: #262626 !important;
  margin: 0;
}


#iubenda_policy .box_10 {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}

#iubenda_policy .box_10 > .w_icon_24, #iubenda_policy .box_10 > .w_icon_24.expand-click, #iubenda_policy .box_10.expand > .w_icon_24, #iubenda_policy .box_10.expand > .w_icon_24.expand-click {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 10px;
  background-position: 10px 10px;
}

#iubenda_policy .expand .expand-click, #iubenda_policy .expand.expanded .expand-click, #iubenda_policy .box_10.expand .expand-click, #iubenda_policy .box_10.expand.expanded .expand-click {
  border-bottom: 1px dotted #DDD;
  margin-bottom: 10px;
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
}

#iubenda_policy .box_10.expand .expand-click {
  margin: -10px;
  margin-bottom: -10px;
  padding: 12px 25px 13px 10px;
  padding-left: 10px;
}

#iubenda_policy.iubenda_embed_policy .expand-click {
  cursor: default;
}

#iubenda_policy .policyicon_purpose_5 {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAElBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2BtTDCxAAAABXRSTlMAECBAgLf%2B2%2BsAAABGSURBVBhXY2AAA5ZQBwY4YA0NIJfjCjYHygkNDUTmBGPhgOyFc1iB6pE4wSAOUAGCIxoaiOCYhgYjOKqhQThkyODAAR4OAI98N9LK6tL3AAAAAElFTkSuQmCC);
}

#iubenda_policy [class*="policyicon_"] {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}

#iubenda_policy .policyicon_purpose_10, #iubenda_policy .policyicon_purpose_15 {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .policyicon_purpose_9 {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACC0lEQVQ4y7XSO2gWVhQH8BPxDRG%2BEhurMcSI4GsoPqjkZ6BLwcFFHUQJKqbEwRciDqZDF90cpIsILtZHh0KTIdQMgkTRiIshBoWgTRpbsVaxgqRf4uM4JCHfRzpIwXun8%2Bf%2BuHDOifj%2FxwoD2qek7Qat%2FG9Qr1%2FblLRNv%2FqyqKHCjIgIqw3oGE9mmtlQERGhw4DVERFmNFREhG91uq6gxUspnVdlky5dNqlyXkovtSi4rtPe8JeUaq1yWLN9tkVoklJThK1a7HXISrVSehpSGrXb5woWqFZljZNSOmmtBRapUe0Lu4xKOQZSr0633dejS7chKQ25p0%2BvHn3u6Bt7OQFSeuWG3pI6DbvpZ5dc8WwimwTPbYswx49Sei89sDNCpaoI6%2FyqWA5OmxUR4StF6Z0hX5puvyH%2FOmeeudrLwXfjg1prUCo6FuGyty444W89CpYZKQU%2FmF3ywwvVthtxwpwImz1yzjSdpWBYq2nWuzbWoQgX%2FaPOAd%2Br1O55hDOl4LHdDRXqnPVWehLhlPSNgiURFlof4adJMGC7eRERarRKr32t2qBn9lhlg%2BVq7fDbJDhasp%2BfueW9brOscdULv7vntlselnZpadlKH5fSRYvN16ytdJgT4KBGGzVqtNFmv4yndzWrt8WjqSCNGFZUNOxN2Xq8K6%2FD47Et%2FKg7ajAc9edHgz8ciU9%2BPgBKt4%2FTzlslzAAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .icon_general {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}

#iubenda_policy .icon_owner {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwQGBwgJCgsMDQ4PC8YWy5AAAAiElEQVR42o2QwRKFIAhFIcwyRP7%2Fb3uNlkBv0dkw3jODd4AbPHhNC7xAafqjYBRZOzUa0cHmc9IbiZsefIFtiuQ68RS7FUkNnwTWmRewLE9ewSPh73dfCgJbzxkiRxcrDGJhWVxa5MqYr1HzcLSPRo2ojcoZAcyV2F1MzaPoxIqcP4gGkP5BcAIxQBCQ7o5t3AAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .simple_pp > ul > li > ul .iconed {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 2px;
  background-position-y: 26px;
  background-position: 2px 26px;
}

#iubenda_policy .box_10 {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}

#iubenda_policy .box_10 > .w_icon_24, #iubenda_policy .box_10 > .w_icon_24.expand-click, #iubenda_policy .box_10.expand > .w_icon_24, #iubenda_policy .box_10.expand > .w_icon_24.expand-click {
  padding-left: 45px !important;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 10px;
  background-position: 10px 10px;
}

#iubenda_policy .expand .expand-click, #iubenda_policy .expand.expanded .expand-click, #iubenda_policy .box_10.expand .expand-click, #iubenda_policy .box_10.expand.expanded .expand-click {
  border-bottom: 1px dotted #DDD;
  margin-bottom: 10px !important;
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
}

#iubenda_policy ul.for_boxes, #iubenda_policy ul.for_boxes > li, #iubenda_policy ul.unstyled, #iubenda_policy ul.unstyled > li {
  list-style: none;
  padding: 0;
  margin: 0;
}

#iubenda_policy .box_primary {
  border: 1px solid #C0C1C1;
  border-bottom-color: rgb(192, 193, 193);
  border-bottom-color: #A8AAAB;
  -webkit-box-shadow: 0 1px 0 #EBEBEC;
  box-shadow: 0 1px 0 #EBEBEC;
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.1);
  box-shadow: 0 1px 0 rgba(0,0,0,0.1);
  background: #FFF;
}

#iubenda_policy ul.styled, #iubenda_policy ul {
  list-style: disc;
  padding-top: 5px;
}

#iubenda_policy .legal_pp .one_line_col > ul.for_boxes {
  margin-top: 21px !important;
}

#iubenda_policy h1 + p, #iubenda_policy h2 + p, #iubenda_policy h3 + p {
  padding-top: 5px !important;
}

#iubenda_policy p {
  line-height: 19px;
  margin: 0;
  padding-top: 11px;
}

#iubenda_policy a:hover:not(.btn) {
  color: #121518 !important;
  border-bottom-color: #D6D6D6;
  -webkit-transition: 0.1s linear all;
  transition: 0.1s linear all;
}

#iubenda_policy a {
  color: #333B43 !important;
  font-weight: normal;
  border-bottom: 1px solid #F0F0F0;
  border-bottom-color: rgb(240, 240, 240);
  text-decoration: none !important;
}

#iubenda_policy .box_10.expand .expand-click {
  margin: -10px  !important;
  margin-bottom: -10px !important;
  padding: 12px 25px 13px 10px !important;
}

#iubenda_policy .legal_pp .one_line_col {
  float: none;
  border-top: 0;
  padding-bottom: 21px !important;
}

#iubenda_policy .one_line_col {
  zoom: 1;
  float: left;
  width: 100%;
  border-bottom: 1px dotted #DFDFDF;
}

#iubenda_policy .icon_ribbon {
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR42u3OwQkAIQxE0XSWVrazlJpdQdGDC0pQEf7A3ELmibsPV1V9pDM%2FAQAAAAAAAAAAAAAAEAXY1%2BcUwCQnITYD6niL2ASo4z3EaoDKf8qNBQHxArgK8ALKMXCw%2Bim7vwAAAABJRU5ErkJggg%3D%3D);
}

#iubenda_policy .legal_pp .definitions .expand-click.w_icon_24 {
  margin-top: -11px;
  padding: 14px 10px 12px 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 5px;
  background-position-y: 0;
  background-position: 5px 0;
}


#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions {
  margin-top: 60px !important;
}